import { StyleSheet, View } from "react-native";

import { ColorStyle, Spacing } from "../../../constants";
import { OverlaySheetDefinition } from "./overlaySheet.definition/overlaySheet.definition.types";

export const OverlaySheetPaper: OverlaySheetDefinition["Paper"] = ({
  children,
}) => {
  return <View style={styles.container}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
    gap: Spacing.S16,
  },
});
