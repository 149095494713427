import { useCallback, useMemo } from "react";

import { ImageSize } from "../image.type";
import { ImageProxyBuilder } from "../imageProxyBuilder";
import { getClosestImage, ImageSourceWithSize } from "../utils";

type SizeStr = string;
const internalRuntimeRegistry = new Map<
  string,
  Record<SizeStr, ImageSourceWithSize>
>();

/**
 * This should not be necessary since the whole url should be the actual key
 * but until we migrate all minimedia thumbnail url, we need to do this so we can mix thumbnail url and original url cache
 * (otherwise we can't use already loaded thumbnail url as placeholder for classic url)
 *
 * // TODO remove after the rework of minimedia
 * @param url
 */
function getRegistryKeyForUrl(url: string) {
  const baseUrl = new URL(url);
  return `${baseUrl.origin}${baseUrl.pathname}`.replace(
    /-thumbnail(\.\w+$)/,
    "$1",
  );
}

/**
 * Manages the loading and registration of image placeholders based on the provided source.
 * This is used to preview already loaded image while different version of that same image are loading.
 *
 * @param source The URL of the image source to manage placeholders for.
 * @return An object containing:
 *         - placeholder: The closest matching image based on the preferred size.
 *         - registerImageLoaded: A callback to register a loaded image with its size.
 *
 * Example:
 * const { placeholder, registerImageLoaded } = usePlaceholder('http://example.com/image.jpg');
 *
 * @internal
 * it seems that it is important that the placeholder is not a string but an object with a uri property
 * otherwise, the image is not displayed
 * https://github.com/expo/expo/blob/1c2116759769d1188d5b6c863e6136033239a0a5/packages/expo-image/src/ExpoImage.web.tsx#L93C3-L93C73
 */
export function usePlaceholder(source: string | undefined) {
  const registerImageLoaded = useCallback((url: string, size: ImageSize) => {
    const baseUrl = ImageProxyBuilder.fromUrl(url).getBaseUrl();
    const mutableImageLoadedVersion =
      internalRuntimeRegistry.get(baseUrl) || {};

    mutableImageLoadedVersion[`${size.width}x${size.height}`] = {
      uri: url,
      width: size.width,
      height: size.height,
    };
    internalRuntimeRegistry.set(
      getRegistryKeyForUrl(baseUrl),
      mutableImageLoadedVersion,
    );
  }, []);

  const preferredSize = useMemo(() => {
    if (!source) {
      return undefined;
    }

    return ImageProxyBuilder.fromUrl(source).getPreferredSize();
  }, [source]);

  const placeholder = useMemo(() => {
    if (!source || !preferredSize) {
      return undefined;
    }
    const baseUrlStr = ImageProxyBuilder.fromUrl(source).getBaseUrl();

    const closest = getClosestImage(
      Object.values(
        internalRuntimeRegistry.get(getRegistryKeyForUrl(baseUrlStr)) || {},
      ),
      preferredSize,
      { biggerIsBetter: true },
    );

    return closest;
  }, [source, preferredSize]);

  return {
    placeholder,
    registerImageLoaded,
  };
}
