import { Identity } from "../../../../display";
import { OverlaySheetContent } from "../overlaySheet.content";
import { OverlaySheetFooter } from "../overlaySheet.footer";
import { OverlaySheetHeader } from "../overlaySheet.header";
import { OverlaySheetHost } from "../overlaySheet.host";
import { OverlaySheetPaper } from "../overlaySheet.paper";
import { OverlaySheetDefinition } from "./overlaySheet.definition.types";

export const overlaySheetDefinition: OverlaySheetDefinition = {
  Host: OverlaySheetHost,
  Paper: OverlaySheetPaper,
  Header: OverlaySheetHeader,
  Content: OverlaySheetContent,
  GrowingContent: Identity,
  Footer: OverlaySheetFooter,
};
