import { ImageSource } from "expo-image";
import { useMemo } from "react";

import { ImageStaticSizeProps } from "../imageStaticSize";
import { ImageSourceWithSize } from "../utils";

/**
 * Processes the provided image sources array and returns an object containing the original source
 * and an array of sources with their specified sizes.
 *
 * @param source - The image sources that can either be an array of images, each possibly containing width and height properties, or a single image source.
 * @return An object containing the original image source and an array of sources with sizes.
 */
export function useImageSources(source: ImageStaticSizeProps["source"]) {
  return useMemo(() => {
    if (Array.isArray(source)) {
      const _sourcesWithSizes: Array<ImageSourceWithSize> = [];
      let _originalSource: string | ImageSource | undefined;

      for (const src of source) {
        if (src.width && src.height) {
          _sourcesWithSizes.push(src as ImageSourceWithSize);
        } else {
          _originalSource = src;
        }
      }
      return {
        originalSource: _originalSource,
        sourcesWithSizes: _sourcesWithSizes,
      };
    }
    return { originalSource: source, sourcesWithSizes: [] };
  }, [source]);
}
