import { Image as ExpoImage, ImageProps as ExpoImageProps } from "expo-image";
import { useMemo } from "react";
import { Platform } from "react-native";

import { GeoCoordinates } from "@kraaft/shared/core/types";

import { ImagePropsPreferredSizeHelper, ImageSource } from "./image.type";
import { ImageDynamicSize } from "./imageDynamicSize";
import { ImageFormat, ImageProxyBuilder } from "./imageProxyBuilder";
import { ImageStaticSize } from "./imageStaticSize";
import { isKraaftImageSource } from "./utils";

export type { ImagePropsPreferredSizeHelper, ImageSource };
export { type ImageFormat, ImageProxyBuilder };

export type ImageProps<
  Source extends ImageSource = ImageSource,
  isLocal extends boolean = boolean,
> = Omit<ExpoImageProps, "source"> & {
  quality?: number;
  enableDynamicResizing?: boolean;
  debugProxy?: boolean;
  geolocation?: GeoCoordinates;
  format?: ImageFormat;
  source: Source;
} & ImagePropsPreferredSizeHelper<Source, isLocal>;

export const Image = <
  Source extends ImageSource = ImageSource,
  isLocal extends boolean = boolean,
>({
  enableDynamicResizing = true,
  preferredSize,
  source,
  debugProxy = false,
  testID: expoTestID,
  ...props
}: ImageProps<Source, isLocal>) => {
  // When using react-native Image, react-native-web does the translation between testID and data-testid
  // but expo-image come with its own web Image implementation and testId is not translated to data-testid
  const testIdProps = useMemo(() => {
    return Platform.OS === "web"
      ? { "data-testid": expoTestID }
      : { testID: expoTestID };
  }, [expoTestID]);

  if (!isKraaftImageSource(source)) {
    return <ExpoImage source={source} {...testIdProps} {...props} />;
  }

  if (enableDynamicResizing) {
    return (
      <ImageDynamicSize
        source={source}
        {...testIdProps}
        {...props}
        debugProxy={debugProxy}
        preferredSize={preferredSize}
      />
    );
  }

  return (
    <ImageStaticSize
      source={source}
      {...testIdProps}
      {...props}
      preferredSize={preferredSize}
    />
  );
};
