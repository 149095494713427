// https://gitlab.com/kraaft/monorepo/blob/94385b98ba740f6641ff0b27b9ee46ceed370d9e/backend/shared/src/models/image/imageUtils.ts#L35

import { ImageSource } from "expo-image";
import { MarkRequired } from "ts-essentials";
export { getClosestImage } from "./getClosestImage";
export { isKraaftImageSource } from "./storageUrls";

export const preferredSizeByName = {
  thumbnail: { width: 256, height: 256 },
  small: { width: 640, height: 640 },
  medium: { width: 1600, height: 1600 },
  large: { width: 4032, height: 4032 },
};

export type PreferredSizeName = keyof typeof preferredSizeByName;
export const preferredSizeNameKeys = Object.keys(
  preferredSizeByName,
) as Array<PreferredSizeName>;

export type ImageSourceWithSize = MarkRequired<ImageSource, "width" | "height">;
