import {
  Image as ExpoImage,
  ImageLoadEventData,
  ImageSource,
} from "expo-image";
import React, { useCallback, useMemo } from "react";

import { GeoCoordinates } from "@kraaft/shared/core/types";

import { useImageSources } from "./hooks/useImageSources";
import { usePlaceholder } from "./hooks/usePlaceholder";
import type { ImageProps } from ".";
import { ImageFormat, ImageProxyBuilder } from "./imageProxyBuilder";
import { preferredSizeByName, preferredSizeNameKeys } from "./utils";

export type ImageDynamicSize = Omit<ImageProps, "source" | "local"> & {
  source: Extract<
    ImageProps["source"],
    Array<ImageSource> | string | ImageSource
  >;
  geolocation?: GeoCoordinates;
  format?: ImageFormat;
  quality?: number;
  debugProxy?: boolean;
};

const ImageDynamicSize_ = ({
  source,
  preferredSize,
  format,
  quality,
  geolocation,
  debugProxy,
  onLoad,
  ...props
}: ImageDynamicSize) => {
  const { originalSource } = useImageSources(source);

  const originalSourceUrl =
    typeof originalSource === "object" ? originalSource.uri : originalSource;

  const setImageSize = useCallback(
    (imageProxyBuilder: ImageProxyBuilder, width: number, height: number) => {
      if (props.contentFit === "cover" || props.resizeMode === "cover") {
        return imageProxyBuilder.setCoverMax(width, height);
      }
      return imageProxyBuilder.setContainMax(width, height);
    },
    [props.contentFit, props.resizeMode],
  );

  const computedSource = useMemo(() => {
    if (!originalSourceUrl) {
      return undefined;
    }

    const imageBuilder = ImageProxyBuilder.fromUrl(originalSourceUrl)
      .setGeolocation(geolocation)
      .setFormat(format)
      .setQuality(quality)
      .setDebugMode(debugProxy);

    if (preferredSize === "original" || !preferredSize) {
      return imageBuilder.buildUrl();
    }

    if (typeof preferredSize === "object") {
      return setImageSize(
        imageBuilder,
        preferredSize.width,
        preferredSize.height,
      ).buildUrl();
    }
    if (preferredSizeNameKeys.includes(preferredSize)) {
      const size = preferredSizeByName[preferredSize];
      return setImageSize(imageBuilder, size.width, size.height).buildUrl();
    }
    return undefined;
  }, [
    originalSourceUrl,
    geolocation,
    format,
    quality,
    debugProxy,
    preferredSize,
    setImageSize,
  ]);

  const { placeholder, registerImageLoaded } = usePlaceholder(computedSource);

  const placeholderContentFit = (() => {
    if (props.placeholderContentFit) {
      return props.placeholderContentFit;
    }
    if (props.contentFit) {
      return props.contentFit;
    }
    if (props.resizeMode === "contain" || props.resizeMode === "cover") {
      return props.resizeMode;
    }
    return undefined;
  })();

  const onImageLoad = useCallback(
    (event: ImageLoadEventData) => {
      registerImageLoaded(event.source.url, {
        width: event.source.width,
        height: event.source.height,
      });
      onLoad?.(event);
    },
    [registerImageLoaded, onLoad],
  );

  return (
    <ExpoImage
      {...props}
      source={computedSource}
      placeholder={placeholder}
      placeholderContentFit={placeholderContentFit}
      onLoad={onImageLoad}
    />
  );
};

export const ImageDynamicSize = React.memo(ImageDynamicSize_);
