// https://gitlab.com/kraaft/monorepo/blob/94385b98ba740f6641ff0b27b9ee46ceed370d9e/backend/shared/src/models/image/imageUtils.ts#L35

import { ImageSource } from "expo-image";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";

import { ImageSourceWithSize } from ".";

export type GetClosestImageOptions = {
  biggerIsBetter: boolean;
};

/**
 * Retrieves the closest matching image source based on the preferred size.
 * To find the closest we compare a ratio for each source.
 * The ratio corresponds to the source diagonal length by the preferred diagonal length
 *
 * Example usage:
 * ```typescript
 * const sources = [
 *    { src: "image1.jpg", width: 200, height: 200 },
 *    { src: "image2.jpg", width: 700, height: 700 },
 * ];
 * const preferredSize = { width: 400, height: 400 };
 * const closestImage = getClosestImage(sources, preferredSize);
 * // returns: { src: "image2.jpg", width: 700, height: 700 }
 * ```
 *
 * @param sources - Array of image sources with their respective sizes.
 * @param preferredSize - The preferred width and height for the image.
 * @param options - optional parameters
 * @param options.biggerIsBetter - use `true` if for the same difference you prefer the biggest image.
 * @return The source of the image that most closely matches the preferred size, or undefined if no sources are provided.
 */
export function getClosestImage(
  sources: ImageSourceWithSize[],
  preferredSize: {
    width: number;
    height: number;
  },
  options?: GetClosestImageOptions,
): ImageSource | undefined {
  const { biggerIsBetter = true } = options || {};
  const referenceDiagonalLength = calculateDiagonalLength(preferredSize);

  const firstSource = sources[0];
  if (!firstSource) {
    return undefined;
  }

  return sources.reduce<{
    closestSource: ImageSourceWithSize;
    ratio: number;
  }>(
    (previous, currentSource) => {
      const currentDiagonalLength = calculateDiagonalLength(currentSource);
      if (!previous.closestSource) {
        return { closestSource: currentSource, ratio: currentDiagonalLength };
      }
      const currentRatio = floorDecimals(
        Math.abs(
          1 -
            Math.min(currentDiagonalLength, referenceDiagonalLength) /
              Math.max(currentDiagonalLength, referenceDiagonalLength),
        ),
        3,
      );
      if (currentRatio < previous.ratio) {
        return { closestSource: currentSource, ratio: currentRatio };
      }
      if (currentRatio === previous.ratio) {
        return {
          closestSource: biggerIsBetter
            ? maxBy(
                [previous.closestSource, currentSource],
                (it) => it.width,
              ) || previous.closestSource
            : minBy(
                [previous.closestSource, currentSource],
                (it) => it.width,
              ) || previous.closestSource,
          ratio: currentRatio,
        };
      }
      return previous;
    },
    { closestSource: firstSource, ratio: Number.POSITIVE_INFINITY },
  ).closestSource;
}

/**
 * Calculates the diagonal length of a rectangle based on its width and height.
 *
 * @return The length of the diagonal.
 */
function calculateDiagonalLength({
  width,
  height,
}: { width: number; height: number }) {
  return calculateDistance({ x: 0, y: 0 }, { x: width, y: height });
}

type Point = { x: number; y: number };

/**
 * Calculates the distance between two points.
 *
 * @param pA - The first point.
 * @param pB - The second point.
 * @return The distance between the two points.
 */
function calculateDistance(pA: Point, pB: Point): number {
  return Math.sqrt(Math.abs(pB.x - pA.x) ** 2 + Math.abs(pB.y - pA.y) ** 2);
}

/**
 * Floors the given number to a specified number of decimal places.
 *
 * @param num The number to be floored.
 * @param decimals The number of decimal places to retain.
 * @return The floored number.
 *
 * @example
 * floorDecimals(3.14159, 2); // Returns 3.14
 *
 * @example
 * floorDecimals(2.5678, 1); // Returns 2.5
 */
function floorDecimals(num: number, decimals: number) {
  return Math.floor(num * 10 ** decimals) / 10 ** decimals;
}
