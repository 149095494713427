import { StyleSheet, View } from "react-native";

import { ColorStyle, FontSize, PixelSize, Spacing } from "../../../constants";
import { Text } from "../../../display/text";
import { Button } from "../../../inputs/button";
import { OverlaySheetDefinition } from "./overlaySheet.definition/overlaySheet.definition.types";

export const OverlaySheetHeader: OverlaySheetDefinition["Header"] = ({
  onClose,
  children,
}) => {
  return (
    <View style={styles.root}>
      <View style={styles.spacer} />
      <View style={styles.title}>
        <Text weight="bold" size="TITLE">
          {children}
        </Text>
      </View>
      <View style={styles.close}>
        <Button
          style={styles.closeButton}
          onPress={onClose}
          icon="x-close"
          variant="TERTIARY"
          size="SMALL"
          accessibilityLabel=""
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    height: PixelSize.S48,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: Spacing.S16,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderBottomColor: ColorStyle.SEPARATOR,
    borderBottomWidth: 1,
  },
  title: {
    fontSize: FontSize.TITLE,
    fontWeight: "bold",
    flexGrow: 1,
    textAlign: "center",
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  close: {
    flexGrow: 1,
    flexShrink: 1,
  },
  closeButton: {
    alignSelf: "flex-end",
  },
});
