import { useCallback, useEffect, useState } from "react";

export const useCarouselOpenClose = (isCarouselOpen = false) => {
  const [isOpen, setOpen] = useState(isCarouselOpen ?? false);

  useEffect(() => {
    if (isCarouselOpen) {
      setOpen(true);
    }
  }, [isCarouselOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return { open: isOpen, onClose: handleClose };
};
