import { StyleSheet, View } from "react-native";

import { OverlaySheetDefinition } from "./overlaySheet.definition/overlaySheet.definition.types";

export const OverlaySheetContent: OverlaySheetDefinition["Content"] = ({
  children,
}) => {
  return <View style={styles.root}>{children}</View>;
};

const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    flexShrink: 1,
  },
});
