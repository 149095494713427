import { Image as ExpoImage, ImageSource } from "expo-image";
import { useMemo } from "react";

import { useImageSources } from "./hooks/useImageSources";
import {
  getClosestImage,
  preferredSizeByName,
  preferredSizeNameKeys,
} from "./utils";
import { ImageProps } from ".";

export type ImageStaticSizeProps = Omit<ImageProps, "source" | "local"> & {
  source: Extract<
    ImageProps["source"],
    Array<ImageSource> | string | ImageSource
  >;
};

export const ImageStaticSize = ({
  preferredSize,
  source,
  ...props
}: ImageStaticSizeProps) => {
  const { sourcesWithSizes, originalSource } = useImageSources(source);

  const computedSource = useMemo(() => {
    if (
      sourcesWithSizes.length === 0 ||
      preferredSize === "original" ||
      !preferredSize
    ) {
      return originalSource;
    }
    if (typeof preferredSize === "object") {
      return getClosestImage(sourcesWithSizes, preferredSize);
    }
    if (preferredSizeNameKeys.includes(preferredSize)) {
      const size = preferredSizeByName[preferredSize];
      return getClosestImage(sourcesWithSizes, size);
    }

    console.log(
      "This case does not seems to be handled correctly, found preferredSize ",
      preferredSize,
    );

    return undefined;
  }, [sourcesWithSizes, preferredSize, originalSource]);

  return <ExpoImage {...props} source={computedSource} />;
};
