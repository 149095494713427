import {
  getEnvironment,
  getImageProxyUrl,
} from "@kraaft/shared/constants/environment/environment.utils";
import { GeoCoordinates } from "@kraaft/shared/core/types";

export const imageFormats = ["jpg", "png", "webp", "avif"] as const;
export type ImageFormat = (typeof imageFormats)[number];

export class ImageProxyBuilder {
  private readonly url: string;
  private geoCoordinates?: GeoCoordinates;
  private containMaxWidth?: number;
  private containMaxHeight?: number;
  private coverMaxWidth?: number;
  private coverMaxHeight?: number;
  private format?: ImageFormat;
  private quality?: number;
  private isDebugEnabled?: boolean;

  private constructor(url: string) {
    this.url = url;
  }

  static fromUrl(url: string) {
    if (this.isProxyUrl(url)) {
      return this.parseProxyUrl(url);
    }

    return new ImageProxyBuilder(url);
  }
  public static isProxyUrl(url: string): boolean {
    return url.startsWith(getImageProxyUrl());
  }

  // eslint-disable-next-line complexity
  private static parseProxyUrl(url: string) {
    const searchParams = new URL(url).searchParams;

    const baseUrl = searchParams.get("url");

    if (!baseUrl) {
      throw new Error("Invalid proxy url");
    }

    const proxyUrl = new ImageProxyBuilder(baseUrl);

    const containMaxStr = searchParams.get("containMax");
    if (containMaxStr) {
      const [width, height] =
        containMaxStr.split("x").map((it) => Number.parseFloat(it)) ?? [];

      if (typeof width === "number" && typeof height === "number") {
        proxyUrl.setContainMax(width, height);
      }
    }

    const coverMaxStr = searchParams.get("coverMax");
    if (coverMaxStr) {
      const [width, height] =
        coverMaxStr.split("x").map((it) => Number.parseFloat(it)) ?? [];

      if (typeof width === "number" && typeof height === "number") {
        proxyUrl.setCoverMax(width, height);
      }
    }

    // Apply geolocation operation
    const latStr = searchParams.get("lat");
    const lonStr = searchParams.get("lon");
    if (latStr && lonStr) {
      const lat = Number.parseFloat(latStr);
      const lon = Number.parseFloat(lonStr);
      proxyUrl.setGeolocation({ latitude: lat, longitude: lon });
    }

    // Apply output format operation
    const formatStr = searchParams.get("format");
    if (formatStr && imageFormats.includes(formatStr as ImageFormat)) {
      proxyUrl.setFormat(formatStr as ImageFormat);
    }

    const qualityStr = searchParams.get("quality");
    if (qualityStr) {
      const quality = Number.parseFloat(qualityStr);
      proxyUrl.setQuality(quality);
    }

    if (
      searchParams.has("debug") &&
      searchParams.get("debug") !== "false" &&
      searchParams.get("debug") !== "0"
    ) {
      proxyUrl.setDebugMode(true);
    }
    return proxyUrl;
  }

  setGeolocation(coord: GeoCoordinates | undefined) {
    this.geoCoordinates = coord;
    return this;
  }

  setContainMax(maxWidth: number, maxHeight: number) {
    this.containMaxWidth = maxWidth;
    this.containMaxHeight = maxHeight;
    return this;
  }

  setCoverMax(maxWidth: number, maxHeight: number) {
    this.coverMaxWidth = maxWidth;
    this.coverMaxHeight = maxHeight;
    return this;
  }

  setFormat(format: ImageFormat | undefined) {
    this.format = format;
    return this;
  }

  setQuality(quality: number | undefined) {
    this.quality = quality;
    return this;
  }

  setDebugMode(debug: boolean | undefined) {
    this.isDebugEnabled = debug;
    return this;
  }

  getPreferredSize(): { width: number; height: number } | undefined {
    return this.containMaxWidth && this.containMaxHeight
      ? {
          width: this.containMaxWidth,
          height: this.containMaxHeight,
        }
      : this.coverMaxWidth && this.coverMaxHeight
        ? {
            width: this.coverMaxWidth,
            height: this.coverMaxHeight,
          }
        : undefined;
  }

  getBaseUrl() {
    return this.url;
  }

  buildUrl() {
    if (!getEnvironment().IMAGE_PROXY.ENABLED) {
      return this.url;
    }

    const proxyUrlStr = (() => {
      return getImageProxyUrl();
    })();

    const proxyUrl = new URL(proxyUrlStr);
    proxyUrl.searchParams.set("url", this.url);

    if (this.containMaxWidth && this.containMaxHeight) {
      proxyUrl.searchParams.set(
        "containMax",
        `${this.containMaxWidth}x${this.containMaxHeight}`,
      );
    } else if (this.coverMaxWidth && this.coverMaxHeight) {
      proxyUrl.searchParams.set(
        "coverMax",
        `${this.coverMaxWidth}x${this.coverMaxHeight}`,
      );
    }

    if (this.format) {
      proxyUrl.searchParams.set("format", this.format);
    }
    if (this.quality) {
      proxyUrl.searchParams.set("quality", this.quality.toString());
    }
    if (this.geoCoordinates) {
      proxyUrl.searchParams.set("lat", this.geoCoordinates.latitude.toString());
      proxyUrl.searchParams.set(
        "lon",
        this.geoCoordinates.longitude.toString(),
      );
    }

    if (this.isDebugEnabled) {
      proxyUrl.searchParams.set("debug", "true");
    }
    return proxyUrl.toString();
  }
}
