import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { Spacing } from "../../../constants";
import type { PopupSheetDefinition } from "./popupSheet.definition/popupSheet.definition.types";

export const PopupSheetContent: PopupSheetDefinition["Content"] = ({
  children,
  maxHeight,
  noPadding,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, !noPadding && classes.paddedContainer)}
      style={{ maxHeight }}
    >
      {children}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    flexGrow: 1,
  },
  paddedContainer: {
    padding: `0 ${Spacing.S16}px`,
  },
});
